/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-09 16:00:00
 * Copyright © Techne All rights reserved
 */
.user_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .workout_top {
        margin: 60px 0 30px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
    }
}
//user detail
.user_detailMain {
    .user_info {
        margin-top: 40px;
        width: 100%;
        // height: 201px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 1px solid #e1e1e1;
        padding: 20px 20px 0 20px;
        position: relative;
        .userInfo_top {
            padding-bottom: 20px;
            border-bottom: 1px solid #e1e1e1;
        }
        .userImg {
            width: 102px;
            min-width: 102px;
            height: 102px;
            background: #ccc;
            border-radius: 12px 12px 12px 12px;
            opacity: 1;
            border: 1px solid #ffffff;
        }
        .user_name {
            font-size: 32px;
            font-family: var(--primary-semiBold-font);
            font-weight: normal;
            color: #12131a;
            line-height: 38px;
        }
        .user_tips {
            font-size: 20px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #12131a;
            line-height: 23px;
            margin-top: 14px;
        }
        .user_statusBox {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 94px;
            height: 32px;
            background: rgba(130, 199, 79, 0.1);
            border-radius: 6px 6px 6px 6px;
            font-size: 14px;
            font-family: var(--primary-semiBold-font);
            color: #82c74f;
            &.suspended {
                background: rgba(255, 66, 45, 0.1);
                color: #fc5555;
            }
        }
        .user_tabItem {
            font-size: 16px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #989898;
            line-height: 24px;
            margin-right: 50px;
            padding: 17px 0;
            cursor: pointer;
            position: relative;
            transition: all 0.2s;
            user-select: none;
            &.active {
                color: #222222;
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 5px;
                    background: #097dff;
                }
            }
        }
    }
}
.team_box,
.project_box,
.subscription_box {
    margin-top: 40px;
    h4 {
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #12131a;
        line-height: 19px;
        margin-bottom: 20px;
    }
    .edit_btn {
        width: 50px;
        height: 28px;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid #a3a3a3;
        cursor: pointer;
        user-select: none;
        font-size: 11px;
        font-family: var(--primary-medium-font);
        font-weight: 500;
        color: #222222;
        margin-right: 10px;
    }
    .remove_btn {
        width: 72px;
        height: 28px;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        border: 1px solid #ff422d;
        cursor: pointer;
        user-select: none;
        font-size: 11px;
        font-family: var(--primary-medium-font);
        font-weight: 500;
        color: #ff422d;
    }
}
.profile_box,
.subscription_box {
    margin-top: 50px;
    .line_box {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 40px 0;
    }
    .info_box {
        font-family: var(--primary-light-font);
        font-size: 20px;
        font-weight: 400;
        color: #222222;
        line-height: 23px;
        margin-bottom: 24px;
        .info_tit {
            min-width: max-content;
        }
        .info_msg {
            font-family: var(--primary-medium-font);
            margin-left: 20px;
        }
    }
}
.auth_btn {
    width: 233px;
    height: 56px;
    background: #eaf1fe;
    border-radius: 8px 8px 8px 8px;
    font-size: 17px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #151515;
    cursor: pointer;
    user-select: none;
    &.active {
        background: #097dff;
        color: #ffffff;
    }
}
.modal_tips {
    font-size: 16px;
    font-family: var(--primary-light-font);
    font-weight: 400;
    color: #222222;
    line-height: 19px;
    width: 100%;
    text-align: center;
}
