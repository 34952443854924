.r-pagination {
    margin-top: 20px;
    .ant-pagination-prev,
    .ant-pagination-next {
        min-width: 28px;
        height: 28px;
        background: rgba(9, 125, 255, 0.1);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        .ant-pagination-item-link {
            color: #097dff;
            display: flex;
            align-items: center;
            justify-content: center;
            &:disabled {
                // color: #909090;
                opacity: 0.3;
            }
        }
    }
    .ant-pagination-item {
        width: 28px;
        min-width: 28px;
        height: 28px;
        // background: #097dff;
        border-radius: 8px 8px 8px 8px;
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #747474;
        margin-inline-end: 4px;
        a {
            padding: 0;
        }

        &.ant-pagination-item-active {
            background: #097dff;
            a {
                color: #ffffff;
            }
        }
    }
    .opeationpage_btn {
        width: 74px;
        height: 28px;
        background: rgba(9, 125, 255, 0.1);
        border-radius: 8px 8px 8px 8px;
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #222;
        cursor: pointer;
        user-select: none;
        transition: all 0.1s;
        .btn_icon {
            width: 16px;
            height: 16px;
            background-image: url('../../assets/images/chevrons-left.png');
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 4px 0 0;
            &.btn_rightIcon {
                background-image: url('../../assets/images/chevrons-right.png');
                margin: 0 0 0 4px;
            }
        }
    }
}
