.statistics_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .top_tips {
        margin: 50px 0 20px 0;
        font-size: 18px;
        font-family: var(--primary-medium-font);
        font-weight: 500;
        color: #222222;
        line-height: 21px;
    }
    .statistics_top {
        .statistics_item {
            flex: 1;
            height: 160px;
            background: #ffffff;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #e1e1e1;
            padding: 22px 25px;
            &:nth-child(2) {
                margin: 0 15px;
            }
            .statistics_item_label {
                font-size: 15px;
                font-family: var(--primary-light-font);
                font-weight: 400;
                color: #222222;
                margin-bottom: 27px;
            }
            .statistics_item_value {
                width: 100%;
                text-align: center;
                font-size: 32px;
                font-family: var(--primary-semiBold-font);
                font-weight: normal;
                color: #222222;
                line-height: 38px;
            }
        }
    }
    .tablex_main {
        margin-top: 50px;
        .computedNum_box {
            flex: 1;
            min-width: 514px;
            &:nth-child(1) {
                margin-right: 20px;
            }
            .tab_tips {
                font-size: 18px;
                font-family: var(--primary-medium-font);
                font-weight: 500;
                color: #222222;
                line-height: 21px;
            }
            .tab_list {
                width: 100%;
                height: 606px;
                background: #ffffff;
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                border: 1px solid #e1e1e1;
                margin-top: 20px;
                padding: 0 25px;
                .tab_header {
                    padding: 26px 0;
                    font-size: 14px;
                    font-family: var(--primary-light-font);
                    font-weight: 400;
                    color: #a3a3a3;
                    line-height: 16px;
                    border-bottom: 1px solid #f3f3f3;
                    margin-bottom: 30px;
                }
                .tab_row {
                    cursor: pointer;
                    font-family: var(--primary-light-font);
                    font-size: 14px;
                    font-weight: 400;
                    color: #222222;
                    line-height: 16px;
                    padding-bottom: 36px;
                }
                .maxW215 {
                    width: 215px;
                    max-width: 215px;
                    min-width: 215px;
                    padding-right: 10px;
                }
                .maxW315 {
                    width: 300px;
                    max-width: 300px;
                    min-width: 300px;
                    padding-right: 10px;
                }
                .userName_box {
                    margin: 0 23px;
                    max-width: 215px;
                }
                .minW125 {
                    min-width: 125px;
                }
                .minW {
                    min-width: max-content;
                }
                .no-data {
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    font-family: var(--primary-light-font);
                    font-weight: 400;
                    color: #a3a3a3;
                    line-height: 16px;
                    margin-top: 208px;
                }
            }
        }
    }
}
