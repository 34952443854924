@font-face {
    font-family: MetropolisSemiBold;
    src: url(./Metropolis/Metropolis-SemiBold.otf);
}
@font-face {
    font-family: MetropolisMedium;
    src: url(./Metropolis/Metropolis-Medium.otf);
}
@font-face {
    font-family: MetropolisRegular;
    src: url(./Metropolis/Metropolis-Regular.otf);
}
