.clear:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
    word-break: break-word;
}
* {
    padding: 0;
    margin: 0;
}
body,
html {
    font-family: var(--primary-light-font);
}
.page_mainBox {
    padding: 40px;
    // min-width: 1190px;
}
