.table_box {
    .ant-table-thead {
        ::before {
            width: 0 !important;
            height: 0 !important;
        }
    }
}
.flex_height {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
}
.default_height {
    height: 486px;
    // .ant-table-thead {
    //     .ant-table-cell {
    //         padding: 10px;
    //     }
    // }
    // .ant-table-tbody {
    //     .ant-table-cell {
    //         padding: 10px;
    //     }
    // }
    .ant-table-cell {
        padding: 9px !important;
    }
}
.data_loading {
    margin: 160px 0 100px 0;
}
