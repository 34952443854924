.project_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .workout_top {
        margin: 60px 0 30px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
    }
}
//project detail
.project_detailMain {
    .project_info {
        margin-bottom: 40px;
        margin-top: 40px;
        width: 100%;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 1px solid #e1e1e1;
        padding: 40px 20px 0 20px;
        position: relative;
        .projectInfo_top {
            padding-bottom: 40px;
            border-bottom: 1px solid #e1e1e1;
        }
        .project_name {
            font-size: 32px;
            font-family: var(--primary-semiBold-font);
            font-weight: normal;
            color: #12131a;
            line-height: 38px;
        }
        .project_tips {
            font-size: 20px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #12131a;
            line-height: 23px;
            margin-top: 14px;
        }
        .user_tabItem {
            font-size: 16px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #989898;
            line-height: 24px;
            margin-right: 50px;
            padding: 17px 0;
            cursor: pointer;
            position: relative;
            transition: all 0.2s;
            user-select: none;
            &.active {
                color: #222222;
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 5px;
                    background: #097dff;
                }
            }
        }
    }
    // .components_btn {
    //     height: calc(100vh - 420px);
    //     overflow-y: auto;
    // }
}
.keyPersion_box {
    .internal_list {
        h3 {
            font-size: 20px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #12131a;
            line-height: 23px;
            margin-bottom: 20px;
        }
        .start_top {
            align-items: flex-start;
        }
        h4 {
            font-size: 16px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: #12131a;
            line-height: 19px;
            max-width: 50%;
            // margin: 20px 0 15px 0;
        }
    }
}
.profile_box {
    .line_box {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 40px 0;
    }
    .info_box {
        font-family: var(--primary-light-font);
        font-size: 20px;
        font-weight: 400;
        color: #222222;
        line-height: 23px;
        margin-bottom: 24px;
        .info_tit {
            min-width: max-content;
        }
        .info_msg {
            font-family: var(--primary-medium-font);
            margin-left: 20px;
            white-space: pre-line;
            word-break: break-word;
            .mini_tips {
                color: #a3a3a3;
                font-family: var(--primary-light-font);
            }
        }
    }
}
.project_valueBox {
    .top_tit {
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #12131a;
        line-height: 19px;
        .showBox_color {
            width: 16px;
            height: 16px;
            background: #d9d9d9;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-left: 10px;
            &.active {
                background: #097dff;
            }
        }
        &.mar50 {
            margin-left: 50px;
        }
    }
    .project_valueLine {
        .titBox {
            font-size: 18px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #222222;
            line-height: 21px;
            margin: 36px 0 10px 0;
        }
        .budget_line {
            width: 480px;
            height: 20px;
            background: #e1e1e1;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-bottom: 8px;
        }
        .actual_line {
            width: 207px;
            height: 20px;
            background: #097dff;
            border-radius: 0px 0px 0px 0px;
        }
        .numBox {
            margin-left: 20px;
            font-size: 16px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #222222;
            line-height: 20px;
            .mini_num {
                color: #a3a3a3;
                margin-left: 3px;
            }
        }
    }
}
.folders_box {
    .leftFolder_box {
        flex: 1;
        // height: calc(100vh - 420px);
        height: 657px;
        .search_file {
            height: 92px;
            padding-bottom: 35px;
            background-color: #ffffff;
            .folder_name {
                font-size: 24px;
                font-family: var(--primary-medium-font);
                font-weight: 500;
                color: #12131a;
                line-height: 28px;
            }
            .fileNumber {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                color: #151515;
                line-height: 19px;
                font-family: var(--primary-light-font);
            }
        }
        .fileBox_new {
            height: calc(100% - 127px);
            overflow-y: auto;
            padding-right: 3px;
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
                border-radius: 0 0 0 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a8a8a8;
                border-radius: 6px;
            }
        }
        .fileList_box {
            flex-wrap: wrap;
            .file_item {
                // max-width: 410px;
                // width: 410px;
                min-width: calc((100% - 15px) / 2);
                max-width: calc((100% - 15px) / 2);
                min-height: 114px;
                background: #ffffff;
                border-radius: 12px 12px 12px 12px;
                opacity: 1;
                border: 1px solid #e1e1e1;
                margin-bottom: 15px;
                padding: 20px;
                &:nth-child(even) {
                    margin-left: 15px;
                }
                .file_info {
                    max-width: calc(100% - 120px);
                    .file_name {
                        font-size: 16px;
                        font-family: var(--primary-medium-font);
                        font-weight: 500;
                        color: #151515;
                        line-height: 23px;
                        max-width: 100%;
                    }
                    .file_type {
                        font-size: 13px;
                        font-family: var(--primary-light-font);
                        font-weight: 400;
                        color: #151515;
                        opacity: 0.5;
                        line-height: 19px;
                    }
                }
                .file_icon {
                    width: 47px;
                    min-width: 47px;
                    height: 47px;
                    border-radius: 6px 6px 6px 6px;
                    margin-right: 15px;
                    background-color: #f1f1f1;
                    .file_iconImg {
                        border-radius: 6px 6px 6px 6px;
                        object-fit: cover;
                    }
                }
                .file_more {
                    width: 20px;
                    height: 20px;
                    background: url('../../../../assets/images/more.png') no-repeat;
                    background-size: contain;
                    cursor: pointer;
                }
            }
        }
    }
    .rightFolder_box {
        margin-left: 15px;
        width: 250px;
        min-width: 250px;
        height: 657px;
        // overflow-y: auto;
        // height: calc(100vh - 420px);
        background: #f9f9f9;
        border-radius: 12px 12px 12px 12px;
        padding: 0 3px 24px 15px;
        position: relative;
        h3 {
            font-size: 16px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: #12131a;
            line-height: 19px;
            padding: 24px 0;
            background: #f9f9f9;
            height: 68px;
        }
        .folders_boxNew {
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
                border-radius: 0 10px 0 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a8a8a8;
                border-radius: 6px;
            }
            padding-right: 10px;
            overflow-y: auto;
            height: calc(100% - 68px);
        }
        .folder_item {
            margin-bottom: 12px;
            padding: 13px 11px;
            width: 100%;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #ffffff;
            cursor: pointer;
            .folder_name {
                user-select: none;
                font-size: 14px;
                font-family: var(--primary-medium-font);
                font-weight: 500;
                color: #151515;
            }
            .folder_icon {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-image: url('../../../../assets/images/Folder.png');
                background-size: contain;
                margin-right: 10px;
            }
            &.active {
                border: 1px solid #097dff;
                .folder_icon {
                    background-image: url('../../../../assets/images/Folder_choose.png');
                    background-size: contain;
                }
            }
        }
    }
}
.w350 {
    width: 350px;
}
