.setting_main,
.version_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
        margin-bottom: 60px;
    }
    .setting-item {
        height: 64px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        margin-bottom: 25px;
        padding: 22px 30px;
        cursor: pointer;
        font-size: 18px;
        font-family: var(--primary-medium-font);
        font-weight: 500;
        color: #222222;
        user-select: none;
        &:hover {
            background: #f1f3f5;
        }
    }
}
.version_main {
    .top_navBox {
        margin: 55px 0 68px 0;
        .r-primary_btn {
            height: 42px;
            width: 210px;
            font-size: 14px;
            &:hover {
                color: #fff;
            }
        }
        h2 {
            margin: 0;
        }
    }
    .tab_list {
        margin-bottom: 30px;
        .tab_item {
            cursor: pointer;
            width: 92px;
            font-size: 16px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #222222;
            padding-bottom: 12px;
            border-bottom: 5px solid #097dff;
        }
    }
}
