.layout-container {
    height: 100vh;
    min-width: 1440px;
    background: #fff;
    position: relative;
    padding-left: 240px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 0 0 0 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 6px;
    }
    .header_nav {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 240px;
        background: #f9f9f9 !important;
        z-index: 999;
        padding: 100px 30px 74px 30px;
        display: flex;
        flex-direction: column;
        .logo {
            width: 180px;
            height: 48px;
            // font-size: 32px;
            // font-family: var(--primary-semiBold-font);
            // font-weight: normal;
            // color: #097dff;
            // line-height: 36px;
            margin-bottom: 65px;
        }
        .menu_items {
            width: 100%;
            background: transparent;
            .menu_icon {
                width: 24px;
                height: 24px;
                // background-color: #097dff;
                background-repeat: no-repeat !important;
                background-size: contain !important;
                margin-right: 10px;
            }
            .menu_item {
                line-height: 25px;
                font-size: 16px;
                font-family: var(--primary-medium-font);
                font-weight: 500;
                color: #222222;
                margin-bottom: 35px;
                padding: 15px 10px;
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                    opacity: 0.8;
                    // background-color: #fff;
                }
                &.active {
                    color: #097dff;
                }
            }
        }
        .logout_btn {
            // position: absolute;
            // bottom: 74px;
            // left: 47px;
            min-height: 42px;
            margin-top: auto;
            width: 145px;
            height: 42px;
            border-radius: 12px 12px 12px 12px;
            opacity: 1;
            border: 1px solid #ff422d;
            font-size: 14px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #ff422d;
            &:hover{
                border: 1px solid #ff422d;
                color: #ff422d;
            }
        }
    }
    .layout-bottom {
        // margin: 40px;
        // @media only screen and (max-width: 1500px) {
        //     margin: 40px;
        // }
        .ant-layout {
            background-color: #fff !important;
        }
    }
}
